'use client';

import { toast } from 'sonner';
import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

export default function MaintenanceToast() {
  const pathname = usePathname();
  const [config, setConfig] = useState(null);

  useEffect(() => {
    async function fetchConfig() {
      try {
        const res = await fetch('/api/maintenance');
        if (res.ok) {
          const data = await res.json();
          setConfig(data);
        }
      } catch (error) {
        console.error('Failed to fetch maintenance config', error);
      }
    }

    fetchConfig();
  }, []);

  useEffect(() => {
    if (config) {
      const { maintenanceMessage, affectedRoutes, globalMaintenance } = config;

      // Check if global maintenance mode is enabled
      if (globalMaintenance) {
        toast.error(maintenanceMessage);
        return;
      }

      // Check if the current route matches affected routes
      const currentRoute = pathname;
      const isRouteAffected = (affectedRoutes as string[])?.some(
        (route: string) => {
          return new RegExp(route.replace('*', '.*')).test(currentRoute);
        }
      );

      if (isRouteAffected) {
        toast.error(maintenanceMessage, {
          duration: 10000,
        });
      }
    }
  }, [pathname, config]);

  return null;
}
